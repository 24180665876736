import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  ModalDefault,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import { CreatePartnerRequest, IPartnersModel } from "../../../../models";
import {
  ModalButtonsWrapper,
  ModalContentWrapper,
} from "../../shared/styles/form-modal.molecule.style";

interface IProps {
  show: boolean;
  onHide: () => void;
  onSaveCallback: (request: CreatePartnerRequest) => Promise<void>;
  onSaveSuccess: () => void;
  setException: (v: any) => void;
  setShowErrorModal: (v: boolean) => void;
}

export const PartnerFormsAddModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const validations = Yup.object<Omit<IPartnersModel, "id">>({
    name: Yup.string().required(),
    securityToken: Yup.string().required(),
    molicarApiKey: Yup.string().required(),
  });

  const formik = useFormik<Omit<IPartnersModel, "id">>({
    initialValues: {
      name: '',
      securityToken: '',
      molicarApiKey: ''
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.onSaveCallback(new CreatePartnerRequest(values));
        props.onSaveSuccess();
        formik.resetForm();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          props.onHide();
          formik.resetForm();
          props.setException(t("GenericApiError"));
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleSubmit = useCallback(
    (e: any) => formik.handleSubmit(e),
    [formik]
  );

  return (
    <ModalDefault
      show={props.show}
      onHide={() => {
        props.onHide();
        formik.resetForm();
      }}
      title={t("AddApiKey")}
      animation={true}
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={() => {
              props.onHide();
              formik.resetForm();
            }}
            disabled={formik.isSubmitting}
          >
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={handleSubmit}
            loading={formik.isSubmitting}
          >
            {t("Save")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper>
        <Form onSubmit={handleSubmit}>
          <Form.Row style={{ paddingLeft: 36 }}>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property={"name"}
              label={t("Name")}
              xs={8}
              sm={7}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property={"securityToken"}
              label={t("KbbKey")}
              md={11}
            />
            <FormikControlAtom
              translate={t}
              formik={formik}
              property={"molicarApiKey"}
              label={t("MolicarKey")}
              md={11}
            />
          </Form.Row>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
