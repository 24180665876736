import styled, { css } from "styled-components";

const WrapperDetails = styled.div`
  .section {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .subsection {
    margin-top: 32px;
  }
`;

const ClicableDiv = styled.div<{ disabled?: boolean }>`
  ${({ disabled, onClick }) =>
    disabled ?
    css`
      cursor: not-allowed;
    ` :
    css`
      cursor: ${onClick ? "pointer" : "default"};
    `
  }
`;

export { ClicableDiv, WrapperDetails };
