import { HttpHelper } from "c4u-web-components";
import {
  CreateApiUserRequest,
  CreatePartnerRequest,
  GetApiUserRequest,
  GetConsuptionReportParams,
  GetRequestingVehicleParams,
  IFindByPlateResponse,
  IFindHistoryByPlate,
  IGetApiUserResponse,
  IGetApiUserResponsePagedResponseModel,
  IGetConsumptionReportResponsePagedResponseModel,
  IGetConsuptionDetailsParams,
  IGetParametersResponse,
  IGetPartnersResponse,
  IGetRequestingVehicleChangesResponse,
  UpdateApiUserRequest,
  UpdateParametersRequest,
  UpdatePartnerRequest,
  UploadDocumentRequest,
} from "../../../models";

import {
  GetRobotsReportRequest,
  IGetRobotsReportResponsePagedResponseModel
} from "../../../models/apis/kbb/api-robots-report.request";
import { C4uClientConfig, KbbHttp, useRequest } from "../config";

export const useKbbBackoffice = () => {
  const { get, post, put, del } = useRequest(KbbHttp, "Backoffice", C4uClientConfig);

  const getApiUsers = async (
    request: GetApiUserRequest
  ): Promise<IGetApiUserResponsePagedResponseModel> => {
    const { data } = await get(
      `getApiUsers${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const getApiUserDetails = async (
    id: number
  ): Promise<IGetApiUserResponse> => {
    const { data } = await get(`getApiUser/${id}`);
    return data;
  };

  const createApiUser = async (
    request: CreateApiUserRequest
  ): Promise<void> => {
    await post(`createApiUser`, request);
  };

  const updateApiUser = async (
    request: UpdateApiUserRequest
  ): Promise<void> => {
    await put(`updateApiUser`, request);
  };

  const getApiPartners = async (): Promise<IGetPartnersResponse> => {
    const { data } = await get(`getApiPartners`);
    return data;
  };

  const updateApiPartner = async (request: UpdatePartnerRequest): Promise<void> => {
    await put(`updateApiPartner`, request);
  };

  const createApiPartner = async (request: CreatePartnerRequest): Promise<void> => {
    await post(`createApiPartner`, request);
  };

  const deleteApiPartner = async (request: { id: number }): Promise<void> => {
    await del(`deleteApiPartner`, request);
  };

  const getParameters = async (): Promise<IGetParametersResponse> => {
    const { data } = await get(`getParameters`);
    return data;
  };

  const updateParameters = async (
    request: UpdateParametersRequest
  ): Promise<void> => {
    await put(`updateParameters`, request);
  };

  const getConsumptionReport = async (
    params: GetConsuptionReportParams
  ): Promise<IGetConsumptionReportResponsePagedResponseModel> => {
    const { data } = await get(
      `consumptionReport${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const getDetailedConsumptionReport = async (
    params: IGetConsuptionDetailsParams
  ): Promise<any> => {
    const { year, month, apiId, kbbApi } = params;
    const response = await get(
      `detailedConsumptionReport/${year}/${month}/${apiId}/${kbbApi}`
    );
    return response;
  };

  const getFindByPlate = async (
    plate: string,
    isOnlyPartner: boolean
  ): Promise<IFindByPlateResponse> => {
    const { data } = await get(`findByPlate/${plate}/${isOnlyPartner}`);
    return data;
  };

  const getFindHistoryByPlate = async (
    plate: string,
    isOnlyPartner: boolean
  ): Promise<IFindHistoryByPlate> => {
    const { data } = await get(`findHistoryByPlate/${plate}/${isOnlyPartner}`);
    return data;
  };

  const updateDecoderReview = async (
    uploadRequest: UploadDocumentRequest,
    onUploadProgress?: any
  ) => {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    };

    const formData = new FormData();
    formData.append("plate", uploadRequest.plate ?? "");
    formData.append("fipeId", uploadRequest.fipeId ?? "");
    formData.append("ituranId", uploadRequest.ituranId?.toString() ?? "");
    formData.append("kbbId", uploadRequest.kbbId?.toString() ?? "");
    formData.append("molicarId", uploadRequest.molicarId ?? "");
    formData.append("brandName", uploadRequest.brandName ?? "");
    formData.append("modelName", uploadRequest.modelName ?? "");
    formData.append("versionName", uploadRequest.versionName ?? "");
    formData.append("modelYear", uploadRequest.modelYear?.toString() ?? "");
    formData.append("CC", uploadRequest.cc?.toString() ?? "");
    formData.append("observation", uploadRequest.observation ?? "");
    formData.append(
      "decoderReviewStatus",
      uploadRequest.decoderReviewStatus?.toString() ?? ""
    );

    formData.append("file", uploadRequest.file ?? new Blob(), "File");

    await post(`updateDecoderReview/`, formData, config);
  };

  const getListReview = async (
    request: GetRequestingVehicleParams
  ): Promise<IGetRequestingVehicleChangesResponse> => {
    const { data } = await get(
      `getListReview${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  const getRobotLogs = async (
    request: GetRobotsReportRequest
  ): Promise<IGetRobotsReportResponsePagedResponseModel> => {
    const { data } = await get(
      `getRobotLogs${HttpHelper.BuildQueryString(request)}`
    );
    return data;
  };

  return {
    createApiUser,
    getApiUserDetails,
    getApiUsers,
    getConsumptionReport,
    getDetailedConsumptionReport,
    getFindByPlate,
    getFindHistoryByPlate,
    getParameters,
    updateApiUser,
    updateParameters,
    updateDecoderReview,
    getListReview,
    getRobotLogs,
    getApiPartners,
    updateApiPartner,
    createApiPartner,
    deleteApiPartner
  };
};
