import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  ModalDefault,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formikHandleErrorHelper } from "../../../../helper";
import { IPartnersModel, UpdatePartnerRequest } from "../../../../models";
import {
  ModalButtonsWrapper,
  ModalContentWrapper,
} from "../../shared/styles/form-modal.molecule.style";

interface IProps {
  show: boolean;
  data: IPartnersModel;
  onHide: () => void;
  onSaveCallback: (request: UpdatePartnerRequest) => Promise<void>;
  onSaveSuccess: () => void;
  setException: (v: any) => void;
  setShowErrorModal: (v: boolean) => void;
  type: "KBB" | "Molicar";
}

export const PartnerFormsEditModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const validations = Yup.object<{ key: string }>({
    key: Yup.string().required(),
  });

  const formik = useFormik<{ key: string }>({
    initialValues: {
      key: ''
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        const request = new UpdatePartnerRequest({
          id: props.data.id,
          securityToken: props.type === "KBB" ? values.key : null,
          molicarApiKey: props.type === "Molicar" ? values.key : null
        })
        await props.onSaveCallback(request);
        props.onSaveSuccess();
      } catch (e: any) {
        if (e?.errors) {
          const errorFormik = formikHandleErrorHelper(e.errors);
          setErrors(errorFormik);
        } else {
          props.onHide();
          props.setException(t("GenericApiError"));
          props.setShowErrorModal(true);
        }
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleSubmit = useCallback(
    (e: any) => formik.handleSubmit(e),
    [formik]
  );

  return (
    <ModalDefault
      show={props.show}
      onHide={props.onHide}
      title={t("EditApiKey #{name}", { name: props.data.name })}
      animation={true}
      footer={
        <ModalButtonsWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={props.onHide}
            disabled={formik.isSubmitting}
          >
            {t("Cancel")}
          </ButtonSecondary>
          <ButtonPrimary
            sizex="md"
            onClick={handleSubmit}
            loading={formik.isSubmitting}
          >
            {t("Save")}
          </ButtonPrimary>
        </ModalButtonsWrapper>
      }
      showFooterBorder
    >
      <ModalContentWrapper>
        <Form onSubmit={handleSubmit}>
          <Form.Row style={{ paddingLeft: 36 }}>
            <FormikControlAtom
              translate={t}
              formik={formik}
              property="key"
              label={props.type === "Molicar" ? t("MolicarKey") : t("KbbKey")}
              md={11}
            />
          </Form.Row>
        </Form>
      </ModalContentWrapper>
    </ModalDefault>
  );
};
