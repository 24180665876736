import { ButtonTertiary } from "c4u-web-components";
import styled from "styled-components";

export const NewPartnerButton = styled(ButtonTertiary)`
	margin: 0;
	width: 200px;
	:focus {
		text-decoration: none;
		box-shadow: none;
	}
`
