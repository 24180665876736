import React from "react";
import { useTranslation } from "react-i18next";
import { ApiPartnersListOrganism } from "../../../../organisms";

export const ApiPartnersListPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("ApiPartners")}</h3>
      <ApiPartnersListOrganism />
    </>
  );
};
