import { TypesHelper } from "c4u-web-components";

export interface IPartnersModel {
  id: number;
  securityToken: string | null;
  name: string;
  molicarApiKey: string | null;
}

export type IGetPartnersResponse = (IPartnersModel & { canDelete: boolean })[]

export class UpdatePartnerRequest {
  id: number | null;
  securityToken: string | null;
  molicarApiKey: string | null;
  constructor({ id, securityToken, molicarApiKey }: Omit<IPartnersModel, "name">) {
    this.id = TypesHelper.toNumberNull(id);
    this.securityToken = TypesHelper.toStringNull(securityToken);
    this.molicarApiKey = TypesHelper.toStringNull(molicarApiKey);
  }
}

export class CreatePartnerRequest {
  name: string | null;
  securityToken: string | null;
  molicarApiKey: string | null;
  constructor({ name, securityToken, molicarApiKey }: Omit<IPartnersModel, "id">) {
    this.name = TypesHelper.toStringNull(name);
    this.securityToken = TypesHelper.toStringNull(securityToken);
    this.molicarApiKey = TypesHelper.toStringNull(molicarApiKey);
  }
}
