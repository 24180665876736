import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  BackOfficeText,
  WrapperHomeBackoffice,
} from "./header-backoffice.molecule.style";

export const HeaderBackofficeMolecule: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WrapperHomeBackoffice>
      <Navbar bg="link" variant="dark" expand="lg">
        <Navbar.Brand as={Link} to={paths.backoffice()}>
          <BackOfficeText>{t("B4U")}</BackOfficeText>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav id="navbar-nav-wrapper-div">
            <NavDropdown title={t("Consumers")} id="consumers-nav-dropdown">
              <NavDropdown.Item as={Link} to={paths.backofficeAdListPage()}>
                {t("Ads")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeKbbZapayListPage()}
              >
                {t("TitleZapay")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeKbbInspectionListPage()}
              >
                {t("InspectionHeaderTitle")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeKbbInsuranceListPage()}
              >
                {t("Insurance")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeKbbTrackerListPage()}
              >
                {t("Tracker")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("Dealerships")} id="dealerships-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeUserAccountListPage()}
              >
                {t("Registration")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeC4uZapayListPage()}
              >
                {t("TitleZapay")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeC4uInspectionListPage()}
              >
                {t("InspectionHeaderTitle")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeInsuranceListPage()}
              >
                {t("Insurance")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeTrackerListPage()}
              >
                {t("Tracker")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("TitleReports")} id="reports-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeAdCustomersReportPage()}
              >
                {t("Express Customer")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeDealershipsReportPage()}
              >
                {t("Dealerships")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeUsersReportPage()}
              >
                {t("Users")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeOnboardingsReportPage()}
              >
                {t("Onboardings")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("Users")} id="users-nav-dropdown">
              <NavDropdown.Item as={Link} to={paths.backofficeUserListPage()}>
                {t("CpvUsers")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeCorporateUserListPage()}
              >
                {t("CpvCorporateUsers")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("Config")} id="config-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeListEmailTemplatesPage()}
              >
                {t("CommunicationsHeaderTitle")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to={paths.backofficeDtMockSync()}>
                {t("SyncDT")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={paths.backofficeDtMockDiscover()}>
                {t("DiscoverDT")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("ApisMenuTitle")} id="config-api-dropdown">
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeApiUsersListPage()}
              >
                {t("ApiUsers")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeApiPartnersListPage()}
              >
                {t("ApiPartners")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeParametersListPage()}
              >
                {t("CacheParameters")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeApiUsageReportPage()}
              >
                {t("ApiUsageReport")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeSearchByPlatePage()}
              >
                {t("SearchPlate")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeRequestingInputting()}
              >
                {t("RequestToChangeVehicleData")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeListRequestingVehicleChanges()}
              >
                {t("ListRequestVehicleChanges")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeRobotLogReports()}
              >
                {t("Robots")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={t("FidelizaTitleMenu")}
              id="config-api-dropdown"
            >
              <NavDropdown.Item as={Link} to={paths.backofficeLenderOperator()}>
                {t("GenerateLink")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeFidelizaLoadData()}
              >
                {t("LoadBase")}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={Link}
                to={paths.backofficeFidelizaLoadDataResidual()}
              >
                {t("LoadBaseResidualValues")}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={Link}
                to={paths.backofficeLenderListUsers()}
              >
                {t("FinancialUsers")}
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeLenderOperatorListUsers()}
              >
                {t("ViewReports")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={paths.backofficeLeadsAdsList()}>
                {t("ViewLeads")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("LogReports")} id="logs-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeInsuranceLogReports()}
              >
                {t("Insurance")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={paths.backofficeTrackerLogReports()}
              >
                {t("Tracker")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </WrapperHomeBackoffice>
  );
};
