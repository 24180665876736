import { CoxIcon } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKbbBackoffice } from "../../../../../hooks";
import { IGetPartnersResponse, IPartnersModel } from "../../../../../models";
import {
  FeedbackModal,
  PaginatableTableList,
  PartnerFormsEditModalMolecule,
  PartnerFormsAddModalMolecule,
} from "../../../../molecules";
import { ClicableDiv } from "../../../shared/shared.style";
import { NewPartnerButton } from "./api-partners-list.organism.style";

export const ApiPartnersListOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { getApiPartners, updateApiPartner, createApiPartner, deleteApiPartner } = useKbbBackoffice();

  const [partnersList, setPartnersList] =
    useState<IGetPartnersResponse>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>();
  const [currentParameter, setCurrentParameter] = useState<IPartnersModel>();
  const [currentType, setCurrentType] = useState<"Molicar" | "KBB">("KBB");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exception, setException] = useState<any>();

  const reloadList = useCallback(async () => {
    setPartnersList(undefined);
    setDataIsLoading(true);
    try {
      const partners = await getApiPartners()
      setPartnersList(partners)
    } catch (error) {
      setPartnersList([]);
      setException(t("GenericApiErrorFetchingData"));
      setShowErrorModal(true);
    } finally {
      setDataIsLoading(false)
    }
  }, [getApiPartners, t]);

  useEffect(() => {
    reloadList();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getEditButton = useCallback(
    (id: number, i: number, type: "Molicar" | "KBB") => (
      <ClicableDiv
        className="text-center w-100"
        key={`link${i}`}
        onClick={
          !partnersList
            ? undefined
            : () => {
              setCurrentParameter(partnersList.find((f) => f.id === id));
              setCurrentType(type)
              setShowEditModal(true);
            }
        }
        title={t("Edit")}
      >
        <CoxIcon
          name="pencil-edit"
          width={16}
          height={16}
          fillColor="#1F3E74"
        />
      </ClicableDiv>
    ),
    [partnersList, t]
  );

  async function handleDelete(id: number){
    if (window.confirm(t("ConfirmDeletePartner"))) {
      try {
        await deleteApiPartner({ id });
        reloadList();
      } catch (error) {
        setException(t("GenericApiErrorFetchingData"));
        setShowErrorModal(true);
      }
    }
  }

  const getDeleteButton = useCallback(
    (id: number, i: number, canDelete: boolean) => (
      <ClicableDiv
        className="text-center w-100"
        key={`link${i}`}
        title={t("Delete")}
        disabled={!canDelete}
        onClick={canDelete ? () => handleDelete(id) : undefined}
      >
        <CoxIcon
          name="trash"
          width={16}
          height={16}
          fillColor={canDelete ? "#1F3E74" : "#94a3b8"}
        />
      </ClicableDiv>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnersList, t]
  );

  const onCloseEditModal = useCallback(() => {
    setShowEditModal(false);
    setCurrentParameter(undefined);
  }, []);

  return (
    <div className="mt-3 mx-lg-5 px-md-5">
      <div className="w-100 py-4 d-flex justify-content-end">
        <NewPartnerButton onClick={() => setShowAddModal(true)}>
          {t("NewPartner")}
        </NewPartnerButton>
      </div>
      <PaginatableTableList
        tableName="api-partners-list"
        dataIsLoading={dataIsLoading}
        thead={["#", t("Client"), t("KbbKey"), "", t("MolicarKey"), "", ""]}
        tbody={partnersList?.map((partner, idx) => [
          partner.id,
          partner.name,
          partner.securityToken || "*********",
          getEditButton(partner.id, idx, "KBB"),
          partner.molicarApiKey || "*********",
          getEditButton(partner.id, idx, "Molicar"),
          getDeleteButton(partner.id, idx, partner.canDelete),
        ])}
      />

      {currentParameter && (
        <PartnerFormsEditModalMolecule
          show={showEditModal}
          data={currentParameter}
          onHide={onCloseEditModal}
          onSaveCallback={updateApiPartner}
          onSaveSuccess={() => {
            onCloseEditModal();
            reloadList();
          }}
          setException={setException}
          setShowErrorModal={setShowErrorModal}
          type={currentType}
        />
      )}

      <PartnerFormsAddModalMolecule
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        onSaveCallback={createApiPartner}
        onSaveSuccess={() => {
          setShowAddModal(false);
          reloadList();
        }}
        setException={setException}
        setShowErrorModal={setShowErrorModal}
      />

      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setException(undefined);
        }}
        content={exception ? exception : t("api_error")}
      />
    </div>
  );
};
